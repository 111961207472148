<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="用户名">
              <a-input v-model="queryParam.username" placeholder="请输入"/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="昵称">
              <a-input v-model="queryParam.nickname" placeholder="请输入"/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="手机号">
              <a-input v-model="queryParam.cellphone" placeholder="请输入"/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24" v-if="false">
            <a-form-item label="邮箱">
              <a-input v-model="queryParam.email" placeholder="请输入"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="企业">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                @keyup.enter.native="$refs.table.refresh(true)"
                allow-clear
                v-model="queryParam.corporation_id"
                placeholder="请输入企业"
                @search="handleCorporationSearch"
                @change="handleCorporationChange"
              >
                <a-select-option v-for="d in dataSource" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="账号分类">
              <a-select
                v-model="queryParam.role"
                placeholder="选择"
              >
                <a-select-option value="">
                  全部
                </a-select-option>
                <a-select-option :value="1">
                  管理员
                </a-select-option>
                <a-select-option :value="2">
                  企业
                </a-select-option>
                <a-select-option :value="3">
                  物业
                </a-select-option>
                <a-select-option :value="4">
                  协管
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator" v-action:add>
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :pageNum="pageNum"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="tags" slot-scope="tags">
        <a-tag
          v-for="tag in tags"
          :key="tag"
          :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
        >
          {{ tag }}
        </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" v-if="record.role===2"/>
          <a @click="handleCorporation(record)" v-if="record.role===2">关联企业</a>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link">
              更多 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="showResetPassWord(record)">重置密码</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="handlePermission(record)">权限</a>
              </a-menu-item>
              <a-menu-item v-if="record.role === 1">
                <a @click="handleConfig(record)">消息通知配置</a>
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm
                  title="确定要删除么？"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="handleDelet(record)"
                >
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>

        </template>
      </span>
    </s-table>
    <create-form
      v-if="visible"
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <UserCorporationForm
      v-if="Corporationvisible"
      ref="createCorporationModal"
      :visible="Corporationvisible"
      :loading="CorporationconfirmLoading"
      :model="Corporationmdl"
      @cancel="handleCorporationCancel"
      @ok="handleCorporationOk"
    />
    <UserPermissionForm
      v-if="Permissionvisible"
      ref="createPermissionModal"
      :visible="Permissionvisible"
      :loading="PermissionLoading"
      :model="Permissionmdl"
      @cancel="handlePermissionCancel"
      @ok="handlePermissionOk"
    />
    <ResetForm
      v-if="resetvisible"
      ref="createResetModal"
      :visible="resetvisible"
      :confirm-loading="resetLoading"
      :model="resetmdl"
      @ok="resetPassWord"
      @cancel="handleResetCancel"
    >

    </ResetForm>
    <config-info-form
      v-if="configVisible"
      ref="configModal"
      :visible="configVisible"
      :loading="configLoading"
      :model="configmdl"
      @configcancel="handleConfigCancel"
      @configok="handleConfigOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { user_create, user_delete, user_list, user_reset_password_update } from '@/api/user'
import { user_corporation_create } from '@/api/user_corporation'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import ResetForm from '../modules/ResetForm'
import configInfoForm from '../modules/ConfigForm'
import UserCorporationForm from '../modules/userCorporationForm'
import UserPermissionForm from '../modules/UserPermissionForm'
import { user_permission_group_create } from '@/api/user_permission'
import { corporation_list } from '@/api/corporation'
import { user_notice_create } from '@/api/user_notice'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    UserCorporationForm,
    UserPermissionForm,
    ResetForm,
    configInfoForm
  },
  data () {
    return {
      pageNum: 1,
      configVisible: false,
      configLoading: false,
      configmdl: {},
      resetvisible: false,
      resetLoading: false,
      resetmdl: {},
      dataSource: [],
      corporation_id: null,
      Permissionmdl: {},
      tip: '',
      corList: [],
      Permissionvisible: false,
      loading: false,
      confirmLoading: false,
      CorporationconfirmLoading: false,
      PermissionLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      Corporationvisible: false,
      mdl: {},
      Corporationmdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '用户名',
          dataIndex: 'username'
        },
        {
          title: '昵称',
          dataIndex: 'nickname'
        },
        /* {
          title: '头像',
          dataIndex: 'avatar'
        }, */
        {
          title: '账号分类',
          dataIndex: 'role',
          customRender: (text) => this.dictionaries.roles.find((item) => !!item[text])[text]
        },
       /* {
          title: '邮箱',
          dataIndex: 'email',
          sorter: true
        }, */
        {
          title: '手机号',
          dataIndex: 'cellphone',
          ellipsis: true
        },
    /*    {
          title: '是否活跃',
          dataIndex: 'is_active',
          sorter: true,
          customRender: (text) => text && '是' || '否'
        }, */
        {
          title: '操作',
          width: '250',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return user_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      }
    }
  },
  created () {
    console.log(this.$Dictionaries)
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    handleConfig (record) {
      this.configmdl = record
      this.configVisible = true
      this.configLoading = false
    },
    handleConfigCancel () {
      this.configVisible = false
      const form = this.$refs.configModal.configForm
      form.resetFields() // 清理表单数据（可不做）
    },
    handleConfigOk (record) {
      const form = this.$refs.configModal.configForm
      this.configLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.user_id = record.id
          // 新增
          user_notice_create(values).then(res => {
            this.configVisible = false
            this.configLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.configLoading = false
        }
      })
    },
    handleCorporationSearch (value) {
      corporation_list({ name: value }).then(({ data }) => {
        const result = data.entries || []
        this.dataSource = !value ? [] : result
      })
    },
    handleCorporationChange (value) {
      this.queryParam.corporation_id = value
    },
    showResetPassWord (record) {
      this.resetmdl = record
      this.resetvisible = true
      this.resetLoading = false
    },
    resetPassWord () {
      const form = this.$refs.createResetModal.form
      this.resetLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
           const param = Object.assign({}, values)
          user_reset_password_update(param, values.id).then(res => {
            this.resetvisible = false
            this.resetLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
            console.log('删除成功------')
          })
        } else {
          this.resetLoading = false
        }
      })
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
      corporation_list({ name: searchText }).then((res) => {
        const reslut = res.data.entries || []
        this.corList = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的企业'
        } else {
          this.tip = ''
        }
        console.log('我是企业列表', reslut)
        const list = reslut.map((item) => item.name)
        this.dataSource = !searchText ? [] : list
      })
    },
    onSelect (value) {
      console.log(value, '我是选择的值', this.corList)
      // eslint-disable-next-line eqeqeq
      this.queryParam.corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id
      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    },
    //  弹框处理区域
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleCorporation (record) {
      this.Corporationmdl = record
      this.Corporationvisible = true
    },
    handlePermission (record) {
      this.Permissionmdl = record
      this.Permissionvisible = true
      this.PermissionLoading = false
    },
    handleResetCancel () {
      this.resetvisible = false
      const form = this.$refs.createResetModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleCorporationCancel () {
      this.Corporationvisible = false
      const form = this.$refs.createCorporationModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePermissionCancel () {
      this.Permissionvisible = false
      const form = this.$refs.createPermissionModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      // this.$emit('onEdit', record)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({ path: '/users/userList/UserInformation/' + record.id })
    },
    handleDelet (record) {
      user_delete(record.id).then((res) => {
        console.log(res)
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
            // 新增
          user_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    // 关联企业弹框数据上传处理函数
    handleCorporationOk () {
      const form = this.$refs.createCorporationModal.form
      this.CorporationconfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values, user_corporation_create)
          this.Corporationvisible = false
          this.CorporationconfirmLoading = false
          // 新增
          /* const param = Object.assign({}, values, { user_id: values.id })
          user_corporation_create(param).then(res => {
            this.Corporationvisible = false
            this.CorporationconfirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()

            console.log('删除成功------')          }) */
        } else {
          this.CorporationconfirmLoading = false
        }
      })
    },
    //  关联权限弹框数据上传处理函数
    handlePermissionOk () {
      const form = this.$refs.createPermissionModal.form
      this.PermissionLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('ctmd', values)
          const param = Object.assign({}, values, { user_id: values.id })
          // 新增
          user_permission_group_create(param).then(res => {
            this.Permissionvisible = false
            this.PermissionLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          }).finally(() => {
            this.PermissionLoading = false
          })
        } else {
          this.PermissionLoading = false
        }
      })
    },

    // 列表
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
